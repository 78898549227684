import useProgressOverviewV6 from "@core/services/nocd-api/clinician-dashboard/queries/useProgressOverviewV6";
import removeDecimalsIfInt from "@core/utils/removeDecimalsIfInt";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Bars2Icon, PlusIcon } from "@heroicons/react/24/solid";
import CalculatorIcon from "@heroicons/react/24/solid/CalculatorIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Skeleton,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";

enum AvailabilityRange {
  THIS_WEEK = "THIS WEEK",
  NEXT_WEEK = "NEXT WEEK",
  PAST_WEEK = "PAST WEEK",
}

const ExplanationModal = ({
  isOpen,
  onClose,
  completedHours,
  lateCancellation,
  ptoHours,
  completedAdjustedHours,
}: {
  isOpen: boolean;
  onClose: () => void;
  completedHours: number | null;
  lateCancellation: number | null;
  ptoHours: number | null;
  completedAdjustedHours: number | null;
}) => {
  const completedhourOrHoursCopy = completedHours === 1 ? "hour" : "hours";
  const lateCancellationhourOrHoursCopy =
    lateCancellation === 1 ? "hour" : "hours";
  const ptohourOrHoursCopy = ptoHours === 1 ? "hour" : "hours";
  const completedAdjustedhourOrHoursCopy =
    completedAdjustedHours === 1 ? "hour" : "hours";

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{ "& .MuiDialog-paper": { width: "fit-content", maxWidth: "none" } }}
    >
      <Box sx={{ padding: "24px" }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <CalculatorIcon style={{ width: "24px", height: "24px" }} />
          <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
            How we calculate completed adjusted hours
          </Typography>
        </Stack>

        <Box display="flex" justifyContent="center" alignItems="center">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          marginTop={3}
          sx={{
            gap: { xs: 1, xl: 3 },
            paddingX: { xs: 2, xl: 4 },
            border: "1px solid #CBD5E1",
            padding: "24px",
            borderRadius: "8px",
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontSize: { lg: "14px", xs: "10px" },
                  fontWeight: "900",
                }}
              >
                COMPLETED HOURS
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography
                sx={{ fontSize: { lg: "16px", xs: "12px" }, fontWeight: "900" }}
              >
                {completedHours ?? 0}
              </Typography>

              <Typography
                sx={{
                  marginLeft: "4px",
                  color: "#ABAEB4",
                  fontSize: { lg: "16px", xs: "12px" },
                }}
              >
                {completedhourOrHoursCopy}
              </Typography>
            </Stack>
          </Stack>
          <PlusIcon
            style={{ width: "20px", height: "20px", color: "#0F172A59" }}
          />
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography
              sx={{
                fontSize: { lg: "14px", xs: "10px" },
                fontWeight: "900",
                textAlign: "center",
              }}
            >
              LATE CANCELLATION
            </Typography>
            <Stack direction="row">
              <Typography
                sx={{ fontSize: { lg: "16px", xs: "12px" }, fontWeight: "900" }}
              >
                {lateCancellation ?? 0}
              </Typography>

              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  marginLeft: "4px",
                  color: "#ABAEB4",
                  fontSize: { lg: "16px", xs: "12px" },
                }}
              >
                {lateCancellationhourOrHoursCopy}
              </Typography>
            </Stack>
          </Stack>
          <Typography
            sx={{ fontSize: "20x", fontWeight: "900", color: "#0F172A59" }}
          >
            <PlusIcon
              style={{ width: "20px", height: "20px", color: "#0F172A59" }}
            />
          </Typography>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography
              sx={{
                fontSize: { lg: "14px", xs: "10px" },
                fontWeight: "900",
                textAlign: "center",
              }}
            >
              PTO HOURS
            </Typography>
            <Stack direction="row">
              <Typography
                sx={{ fontSize: { lg: "16px", xs: "12px" }, fontWeight: "900" }}
              >
                {ptoHours ?? 0}
              </Typography>

              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  marginLeft: "4px",
                  color: "#ABAEB4",
                  fontSize: { lg: "16px", xs: "12px" },
                }}
              >
                {ptohourOrHoursCopy}
              </Typography>
            </Stack>
          </Stack>
          <Bars2Icon
            style={{ width: "20px", height: "20px", color: "#0F172A59" }}
          />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "#f1f5f9",
              padding: "8px",
              borderRadius: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "14px", xs: "10px" },
                fontWeight: "900",

                textAlign: "center",
              }}
            >
              COMPLETED
              <br />
              ADJUSTED HOURS
            </Typography>
            <Stack direction="row">
              <Typography
                sx={{ fontSize: { lg: "16px", xs: "12px" }, fontWeight: "900" }}
              >
                {completedAdjustedHours ?? 0}
              </Typography>

              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  marginLeft: "4px",
                  color: "#ABAEB4",
                  fontSize: { lg: "16px", xs: "12px" },
                }}
              >
                {completedAdjustedhourOrHoursCopy}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        <Typography mt={1}>
          Note: We use approximately 5 hours for a PTO day as an estimate.
        </Typography>

        <Box sx={{ marginTop: "14px" }}>
          <button
            type="button"
            style={{
              backgroundColor: "#6e75ef",
              color: "white",
              borderRadius: "12px",
              padding: "14px",
              border: "none",
              fontWeight: 600,
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            Got it, thanks
          </button>
        </Box>
      </Box>
    </Dialog>
  );
};

const getHoursCopy = (hours: number | null) => (hours === 1 ? "hour" : "hours");

const HoursNeededToHitCommittedHours = ({
  hoursNeeded,
  targetHours,
  availabilityRange,
}: {
  hoursNeeded: number | null;
  targetHours: number | null;
  availabilityRange: AvailabilityRange;
}) => {
  if (!hoursNeeded) {
    if (availabilityRange === AvailabilityRange.NEXT_WEEK) {
      return (
        <Typography variant="body2">
          You&apos;re on the way to hitting your committed hour goal of{" "}
          {targetHours} hours! 🎉
        </Typography>
      );
    }

    return (
      <Typography variant="body2">
        You hit your committed hour goal of {targetHours} hours! 🎉
      </Typography>
    );
  }

  return (
    <Typography variant="body2">
      You{" "}
      {availabilityRange === AvailabilityRange.PAST_WEEK ? "needed " : "need "}
      <Box component="span" color="#3E7FDF" fontWeight="bold">
        {hoursNeeded}
      </Box>{" "}
      more {getHoursCopy(hoursNeeded)} to meet your committed hour goal of{" "}
      <Box component="span" fontWeight="bold">
        {targetHours} hours
      </Box>
    </Typography>
  );
};

const HoursNeededToHitBonusHours = ({
  hoursNeeded,
  targetHours,
  availabilityRange,
}: {
  hoursNeeded: number | null;
  targetHours: number | null;
  availabilityRange: AvailabilityRange;
}) => {
  if (!hoursNeeded) {
    return (
      <Typography variant="body2">
        You hit a bonus multiplier of {targetHours} hours! 🎉
      </Typography>
    );
  }

  return (
    <Typography variant="body2">
      You{" "}
      {availabilityRange === AvailabilityRange.PAST_WEEK ? "needed " : "need "}
      <Box component="span" color="#3E7FDF" fontWeight="bold">
        {hoursNeeded}
      </Box>{" "}
      more {getHoursCopy(hoursNeeded)} to meet a bonus multiplier of{" "}
      <Box component="span" fontWeight="bold">
        {targetHours} hours
      </Box>
    </Typography>
  );
};

const RecommendedHours = ({
  clinicianEmail,
  hasSidebar,
}: {
  clinicianEmail: string | null;
  hasSidebar?: boolean;
}) => {
  const { data, error, isLoading } = useProgressOverviewV6(
    {
      staleTime: clinicianEmail ? 0 : Infinity,
    },
    clinicianEmail ?? null
  );

  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);

  const [availabilityRange, setAvailabilityRange] = useState<AvailabilityRange>(
    AvailabilityRange.THIS_WEEK
  );
  const hoursMapping = {
    [AvailabilityRange.THIS_WEEK]: {
      selectTitle: "This week's forecast",
      completedHoursTitle: "Completed",
      completedHours: removeDecimalsIfInt(+data?.completed_hours_this_week),
      lateCancellation: removeDecimalsIfInt(+data?.late_cancellation_this_week),
      ptoHours: removeDecimalsIfInt(+data?.pto_hours_this_week),
      completedAdjustedHours: removeDecimalsIfInt(
        +data?.completed_adjusted_hours_this_week
      ),
      scheduledHours: removeDecimalsIfInt(+data?.scheduled_hours_this_week),
      scheduledHoursTitle: "Scheduled",
      cancelledHoursTitle: "# Est. Cancels",
      projectedCancelled: removeDecimalsIfInt(
        +data?.projected_cancelled_this_week
      ),
      projectedCompleted: removeDecimalsIfInt(
        +data?.projected_completed_this_week
      ),
      projectedCompletedTitle: "Likely to Complete",
      moreHoursNeeded: removeDecimalsIfInt(+data?.more_hours_needed_this_week),
      moreHoursNeededBonus: removeDecimalsIfInt(
        +data?.more_hours_needed_bonus_this_week
      ),
      targetHoursBonus: removeDecimalsIfInt(
        +data?.bonus_target_hours_this_week
      ),
      targetHours: removeDecimalsIfInt(+data?.committed_hours),
    },
    [AvailabilityRange.NEXT_WEEK]: {
      selectTitle: "Next week's forecast",
      completedHoursTitle: "Completed",
      completedHours: removeDecimalsIfInt(+data?.completed_hours_next_week),
      lateCancellation: removeDecimalsIfInt(+data?.late_cancellation_next_week),
      ptoHours: removeDecimalsIfInt(+data?.pto_hours_next_week),
      completedAdjustedHours: removeDecimalsIfInt(
        +data?.completed_adjusted_hours_next_week
      ),
      scheduledHours: removeDecimalsIfInt(+data?.scheduled_hours_next_week),
      scheduledHoursTitle: "Scheduled",
      cancelledHoursTitle: "# Est. Cancels",
      projectedCancelled: removeDecimalsIfInt(
        +data?.projected_cancelled_next_week
      ),
      projectedCompleted: removeDecimalsIfInt(
        +data?.projected_completed_next_week
      ),
      projectedCompletedTitle: "Likely to Complete",
      moreHoursNeeded: removeDecimalsIfInt(+data?.more_hours_needed_next_week),
      moreHoursNeededBonus: removeDecimalsIfInt(
        +data?.more_hours_needed_bonus_next_week
      ),
      targetHoursBonus: removeDecimalsIfInt(
        +data?.bonus_target_hours_next_week
      ),
      targetHours: removeDecimalsIfInt(+data?.committed_hours_next_week),
    },
    [AvailabilityRange.PAST_WEEK]: {
      selectTitle: "Last week's hours",
      completedHoursTitle: "Completed",
      completedHours: removeDecimalsIfInt(+data?.completed_hours_past_week),
      lateCancellation: removeDecimalsIfInt(+data?.late_cancellation_past_week),
      ptoHours: removeDecimalsIfInt(+data?.pto_hours_past_week),
      completedAdjustedHours: removeDecimalsIfInt(
        +data?.completed_adjusted_hours_past_week
      ),
      scheduledHours: removeDecimalsIfInt(+data?.scheduled_hours_past_week),
      scheduledHoursTitle: "Scheduled",
      cancelledHoursTitle: "Cancelled",
      projectedCancelled: removeDecimalsIfInt(
        +data?.projected_cancelled_past_week
      ),
      projectedCompleted: removeDecimalsIfInt(
        +data?.projected_completed_past_week
      ),
      projectedCompletedTitle: "Completed",
      moreHoursNeeded: removeDecimalsIfInt(+data?.more_hours_needed_past_week),
      moreHoursNeededBonus: removeDecimalsIfInt(
        +data?.more_hours_needed_bonus_past_week
      ),
      targetHoursBonus: removeDecimalsIfInt(
        +data?.bonus_target_hours_past_week
      ),
      targetHours: removeDecimalsIfInt(+data?.committed_hours_past_week),
    },
  };

  const {
    completedHours,
    lateCancellation,
    ptoHours,
    completedAdjustedHours,
    scheduledHours,
    projectedCancelled,
    projectedCompleted,
    completedHoursTitle,
    scheduledHoursTitle,
    cancelledHoursTitle,
    projectedCompletedTitle,
    selectTitle,
    moreHoursNeeded,
    targetHours,
    moreHoursNeededBonus,
    targetHoursBonus,
  } = hoursMapping[availabilityRange] || {};

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        isLoading ? (
          <Skeleton width="100%" height="80%" />
        ) : error ? (
          <Typography color="error.main" variant="body2">
            {error.message}
          </Typography>
        ) : (
          <Stack flex={1}>
            <Stack
              spacing={{
                xs: 0,
                md: 1,
                lg: 0,
              }}
              direction={{ xs: "row", md: "column", lg: "row" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#ABAEB4",
                    fontWeight: "700",
                  }}
                >
                  SESSION HOURS
                </Typography>
                <Tooltip
                  arrow
                  title="Based on past attendance of your members, you may need more hours available to meet your goal for weekly therapy hours. We’ve calculated a suggested number of hours for you to have open."
                  placement="top"
                >
                  <InformationCircleIcon
                    style={{
                      color: "#0F172A59",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </Tooltip>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  sx={{
                    backgroundColor: "#EDF5FE",
                    borderColor: "#A0C7FA",
                    "&:hover": {
                      backgroundColor: "#EDF5FE",
                      borderColor: "#3E7FDF",
                    },
                    color: "#3E7FDF",
                    fontWeight: "bold",
                  }}
                  variant="outlined"
                  size="small"
                  endIcon={<ExpandMoreIcon />}
                  onClick={() => {
                    if (availabilityRange === AvailabilityRange.THIS_WEEK) {
                      setAvailabilityRange(AvailabilityRange.NEXT_WEEK);
                    }

                    if (availabilityRange === AvailabilityRange.NEXT_WEEK) {
                      setAvailabilityRange(AvailabilityRange.PAST_WEEK);
                    }

                    if (availabilityRange === AvailabilityRange.PAST_WEEK) {
                      setAvailabilityRange(AvailabilityRange.THIS_WEEK);
                    }
                  }}
                >
                  {selectTitle}
                </Button>
              </Stack>
            </Stack>

            <Stack
              sx={{
                flex: 1,
                paddingX: hasSidebar ? { xl: 12, lg: 5 } : { xs: "24px" },
              }}
              spacing={1}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Stack alignItems="center">
                <Stack direction="row" alignItems="center">
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      fontSize: { xs: "12px", lg: "14px" },
                      fontWeight: "bold",
                    }}
                  >
                    {completedHoursTitle}
                  </Typography>

                  <IconButton
                    size="small"
                    type="button"
                    onClick={() => setIsExplanationModalOpen(true)}
                  >
                    <Box
                      sx={{
                        width: "16px",
                        height: "20px",
                        alignItems: "center",
                      }}
                    >
                      <CalculatorIcon
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "#0F172A59",
                        }}
                      />
                    </Box>
                  </IconButton>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography fontSize="16px" fontWeight="bold">
                    {completedAdjustedHours}
                  </Typography>
                  <Typography fontSize="14px" color="#0F172A59">
                    {getHoursCopy(completedAdjustedHours)}
                  </Typography>
                </Stack>
              </Stack>

              <Typography fontWeight="bold" fontSize="18px">
                +
              </Typography>

              <Stack alignItems="center">
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: { xs: "12px", lg: "14px" },
                    fontWeight: "bold",
                  }}
                >
                  {scheduledHoursTitle}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography fontSize="16px" fontWeight="bold">
                    {scheduledHours}
                  </Typography>
                  <Typography fontSize="14px" color="#0F172A59">
                    {getHoursCopy(scheduledHours)}
                  </Typography>
                </Stack>
              </Stack>

              <Typography fontWeight="bold" fontSize="18px">
                -
              </Typography>

              <Stack alignItems="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      fontSize: { xs: "12px", lg: "14px" },
                      fontWeight: "bold",
                    }}
                  >
                    {cancelledHoursTitle}
                  </Typography>

                  {availabilityRange === AvailabilityRange.PAST_WEEK ? null : (
                    <Tooltip
                      arrow
                      title="The number of hours we think will go unfilled by the end of the week"
                      placement="top"
                    >
                      <InformationCircleIcon
                        style={{
                          color: "#0F172A59",
                          height: "16px",
                          width: "16px",
                        }}
                      />
                    </Tooltip>
                  )}
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography fontSize="16px" fontWeight="bold">
                    {projectedCancelled}
                  </Typography>
                  <Typography fontSize="14px" color="#0F172A59">
                    {getHoursCopy(projectedCancelled)}
                  </Typography>
                </Stack>
              </Stack>

              <Typography fontWeight="bold" fontSize="18px">
                =
              </Typography>

              <Stack alignItems="center">
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: { xs: "12px", lg: "14px" },
                    fontWeight: "bold",
                  }}
                >
                  {projectedCompletedTitle}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography fontSize="16px" fontWeight="bold">
                    {projectedCompleted}
                  </Typography>
                  <Typography fontSize="14px" color="#0F172A59">
                    {getHoursCopy(projectedCompleted)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            {data !== null ? (
              <HoursNeededToHitCommittedHours
                hoursNeeded={moreHoursNeeded}
                targetHours={targetHours}
                availabilityRange={availabilityRange}
              />
            ) : null}

            {data !== null && data?.show_bonus_target ? (
              <HoursNeededToHitBonusHours
                hoursNeeded={moreHoursNeededBonus}
                targetHours={targetHoursBonus}
                availabilityRange={availabilityRange}
              />
            ) : null}
          </Stack>
        )
      }

      <ExplanationModal
        isOpen={isExplanationModalOpen}
        onClose={() => setIsExplanationModalOpen(false)}
        completedHours={completedHours}
        lateCancellation={lateCancellation}
        ptoHours={ptoHours}
        completedAdjustedHours={completedAdjustedHours}
      />
    </>
  );
};

interface ClinicianRecommendedHoursProps extends StackProps {
  clinicianEmail?: string | null;
  hasSidebar?: boolean;
}
const ClinicianRecommendedHours = ({
  clinicianEmail,
  hasSidebar,
  ...props
}: ClinicianRecommendedHoursProps) => {
  return (
    <>
      <Stack
        padding={3}
        {...props}
        flex={1}
        sx={{
          textAlign: "center",
          border: "1px solid #CBD5E1",
          borderRadius: "8px",
          height: "200px",
        }}
      >
        <RecommendedHours
          clinicianEmail={clinicianEmail ?? null}
          hasSidebar={hasSidebar}
        />
      </Stack>
    </>
  );
};

export default ClinicianRecommendedHours;
