import { useMember } from "@core/services/nocd-api";
import DischargeNoteForm from "@features/member/DischargeNotes/DischargeNoteForm";
import DischargeNoteFormV2 from "@features/member/DischargeNotes/DischargeNoteFormV2";
import CreateTransferOrCoTherapyRequestDialog from "@features/transfers/components/CreateTransferOrCoTherapyRequestDialog";
import CreateTransferRequestDialog from "@features/transfers/components/CreateTransferRequestDialog";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Menu } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Dispatch, SetStateAction, useState } from "react";
// Wrapper component used to defer data fetching until the dialog is opened
const DischargeNoteDialog = ({
  userId,
  isDischargeNoteDialogOpen,
  setIsDischargeNoteDialogOpen,
  onSuccess,
}: {
  isDischargeNoteDialogOpen: boolean;
  setIsDischargeNoteDialogOpen: Dispatch<SetStateAction<boolean>>;
  userId: number;
  onSuccess: () => void;
}) => {
  const { data, mutate } = useMember(userId);
  const { enableDischargeNoteFormV2 } = useFlags();

  if (!data) {
    return null;
  }

  return (
    <>
      {isDischargeNoteDialogOpen &&
        (enableDischargeNoteFormV2 ? (
          <DischargeNoteFormV2
            member={data}
            userId={userId}
            onSuccess={(updatedMember) => mutate(updatedMember).then(onSuccess)}
            setIsDischargeNoteDialogOpen={setIsDischargeNoteDialogOpen}
            isDischargeNoteDialogOpen={isDischargeNoteDialogOpen}
          />
        ) : (
          <DischargeNoteForm
            member={data}
            userId={userId}
            onSuccess={(updatedMember) => mutate(updatedMember).then(onSuccess)}
            setIsDischargeNoteDialogOpen={setIsDischargeNoteDialogOpen}
            isDischargeNoteDialogOpen={isDischargeNoteDialogOpen}
          />
        ))}
    </>
  );
};

interface OptionsMenuProps {
  userId: number;
  onSuccess: () => void;
  disabled: boolean;
}

export default function OptionsMenu({
  userId,
  onSuccess,
  disabled,
}: OptionsMenuProps) {
  const { enableCoTherapyRequest } = useFlags();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isTransferRequestDialogOpen, setIsTransferRequestDialogOpen] =
    useState(false);
  const [isDischargeNoteDialogOpen, setIsDischargeNoteDialogOpen] =
    useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        disabled={disabled}
        size="small"
        variant="outlined"
        aria-controls="succession-options"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        Options
      </Button>
      <Menu
        id="succession-options"
        anchorEl={anchorEl}
        variant="menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div>
          <MenuItem
            key="transfer-request"
            onClick={() => {
              setIsTransferRequestDialogOpen(true);
              handleClose();
            }}
          >
            Request transfer
          </MenuItem>
          <MenuItem
            key="discharge-note"
            onClick={() => {
              setIsDischargeNoteDialogOpen(true);
              handleClose();
            }}
          >
            Discharge
          </MenuItem>
        </div>
      </Menu>

      {
        // eslint-disable-next-line no-nested-ternary
        isTransferRequestDialogOpen && !enableCoTherapyRequest ? (
          <CreateTransferRequestDialog
            userId={userId}
            isOpen
            onClose={() => setIsTransferRequestDialogOpen(false)}
            onSuccess={onSuccess}
          />
        ) : null
      }
      {
        // eslint-disable-next-line no-nested-ternary
        isTransferRequestDialogOpen && enableCoTherapyRequest ? (
          <CreateTransferOrCoTherapyRequestDialog
            userId={userId}
            isOpen
            onClose={() => setIsTransferRequestDialogOpen(false)}
            onSuccess={onSuccess}
          />
        ) : null
      }
      {
        // eslint-disable-next-line no-nested-ternary
        isDischargeNoteDialogOpen ? (
          <DischargeNoteDialog
            userId={userId}
            onSuccess={onSuccess}
            setIsDischargeNoteDialogOpen={setIsDischargeNoteDialogOpen}
            isDischargeNoteDialogOpen={isDischargeNoteDialogOpen}
          />
        ) : null
      }
    </div>
  );
}
