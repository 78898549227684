import useSetConquerorGoal from "@core/services/nocd-api/clinician-dashboard/mutations/useSetConquerorGoal";
import useProgressOverview from "@core/services/nocd-api/clinician-dashboard/queries/useProgressOverview";
import useTreatmentAdherenceBonus from "@core/services/nocd-api/clinician-dashboard/queries/useTreatmentAdherenceBonus";
import {
  BanknotesIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Edit as EditIcon } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Link as MuiLink,
  Slider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Image from "next/image";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import BonusEligibilityExplanationDialog from "./BonusEligibilityExplanationDialog";

const NEW_CONQUEROR_GOAL_COST = 250;
const SUSTAINED_CONQUEROR_GOAL_COST = 50;

const SetTreatmentConquerorGoalDialog = ({
  isOpen,
  onClose,
  activeMembers,
  defaultGoal,
}: {
  isOpen: boolean;
  onClose: () => void;
  activeMembers: number;
  defaultGoal?: number;
}) => {
  const { mutateAsync: setGoal } = useSetConquerorGoal();

  const { handleSubmit, control, watch, formState } = useForm({
    defaultValues: {
      new_conqueror_goal: defaultGoal ?? 0,
    },
  });

  const onSubmit = handleSubmit((formValues) => {
    const transformedFormValues = {
      goal: formValues.new_conqueror_goal,
      goal_type: "new_conqueror_goal",
    };

    const promise = setGoal(transformedFormValues).then(onClose);
    return toast.promise(promise, {
      loading: "Saving treatment conqueror goal",
      success: "Treatment conqueror goal successfully saved",
      error: (error: Error) => error.message,
    });
  });
  const { isSubmitting } = formState;

  const goal = watch("new_conqueror_goal");

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <Box
        component="form"
        sx={{ padding: "24px" }}
        onSubmit={(e) => {
          e.stopPropagation();
          return onSubmit(e);
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
            Treatment Conqueror Calculator
          </Typography>

          <IconButton type="button" onClick={onClose}>
            <XMarkIcon style={{ width: "16px", height: "16px" }} />
          </IconButton>
        </Stack>

        <Typography sx={{ fontSize: "16px", marginTop: "20px" }}>
          Set your goal for the quarter and visualize your potential bonus
          earnings. Every positive member outcome not only contributes to their
          well-being but also increases your financial reward.
        </Typography>

        <Stack sx={{ textAlign: "center" }} mt={2} mb={2}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              sx={{
                fontSize: "14px",
                color: "#ABAEB4",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Treatment Conqueror Goal
            </Typography>
          </Box>

          <Stack direction="column" alignItems="center">
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography sx={{ fontSize: "14px" }}>Member goal</Typography>
              <Typography sx={{ fontSize: "24px", fontWeight: "900" }}>
                {goal}
              </Typography>
            </Stack>
            <Box sx={{ width: 200 }}>
              <Controller
                name="new_conqueror_goal"
                control={control}
                render={({ field }) => (
                  <Slider
                    aria-label="Treatment Conqueror Goal"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    sx={{ color: "#2e8eff" }}
                    max={activeMembers}
                    disabled={isSubmitting}
                  />
                )}
              />
            </Box>
            <Typography sx={{ fontSize: "14px" }}>
              Projected amount goal = <b>$ {goal * NEW_CONQUEROR_GOAL_COST}</b>
            </Typography>
          </Stack>
        </Stack>

        <Typography
          sx={{ fontSize: "16px", fontWeight: 700, marginTop: "10px" }}
        >
          How it works:
        </Typography>

        <Typography sx={{ fontSize: "16px" }}>
          1. <b>Set Your Goal</b>: Enter the number of clients you aim to reach
          with positive outcomes this quarter.
        </Typography>

        <Typography sx={{ fontSize: "16px" }}>
          2. <b>Track Your Progress</b>: Monitor the number of successful
          outcomes and see your potential bonus grow in real-time.
        </Typography>

        <Typography sx={{ fontSize: "16px" }}>
          3. <b>Understand Your Impact</b>:{" "}
          {`Each Treatment Conqueror is valued
            at $${NEW_CONQUEROR_GOAL_COST}, added to your end-of-quarter bonus`}
        </Typography>

        <Typography sx={{ fontSize: "16px" }}>
          4. <b>Stay Within Limits</b>: The maximum number of members you can
          set is limited to number of members eligible to become treatment
          conquerors this quarter.
        </Typography>

        <Box sx={{ marginTop: "16px" }}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ borderRadius: "8px" }}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const SetSustainedConquerorGoalDialog = ({
  isOpen,
  onClose,
  activeMembers,
  defaultGoal,
}: {
  isOpen: boolean;
  onClose: () => void;
  activeMembers: number;
  defaultGoal?: number;
}) => {
  const { mutateAsync: setGoal } = useSetConquerorGoal();

  const { handleSubmit, control, watch, formState } = useForm({
    defaultValues: {
      sustained_conqueror_goal: defaultGoal ?? 0,
    },
  });

  const onSubmit = handleSubmit((formValues) => {
    const transformedFormValues = {
      goal: formValues.sustained_conqueror_goal,
      goal_type: "sustained_conqueror_goal",
    };

    const promise = setGoal(transformedFormValues).then(onClose);
    return toast.promise(promise, {
      loading: "Saving sustained conqueror goal",
      success: "Sustained conqueror goal successfully saved",
      error: (error: Error) => error.message,
    });
  });
  const { isSubmitting } = formState;

  const goal = watch("sustained_conqueror_goal");

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <Box
        component="form"
        sx={{ padding: "24px" }}
        onSubmit={(e) => {
          e.stopPropagation();
          return onSubmit(e);
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
            Sustained Conqueror Calculator
          </Typography>

          <IconButton type="button" onClick={onClose}>
            <XMarkIcon style={{ width: "16px", height: "16px" }} />
          </IconButton>
        </Stack>

        <Typography sx={{ fontSize: "16px", marginTop: "20px" }}>
          Maintain your impact and keep earning. Set your Sustained Conqueror
          goal for this quarter to continue the success streak.
        </Typography>

        <Stack sx={{ textAlign: "center" }} mt={2} mb={2}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              sx={{
                fontSize: "14px",
                color: "#ABAEB4",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Sustained Conqueror Goal
            </Typography>
          </Box>

          <Stack direction="column" alignItems="center">
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography sx={{ fontSize: "14px" }}>Member goal</Typography>
              <Typography sx={{ fontSize: "24px", fontWeight: "900" }}>
                {goal}
              </Typography>
            </Stack>
            <Box sx={{ width: 200 }}>
              <Controller
                name="sustained_conqueror_goal"
                control={control}
                render={({ field }) => (
                  <Slider
                    aria-label="Sustained Conqueror Goal"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    sx={{ color: "#2e8eff" }}
                    max={activeMembers}
                    disabled={isSubmitting}
                  />
                )}
              />
            </Box>
            <Typography sx={{ fontSize: "14px" }}>
              Projected amount goal ={" "}
              <b>$ {goal * SUSTAINED_CONQUEROR_GOAL_COST}</b>
            </Typography>
          </Stack>
        </Stack>

        <Typography
          sx={{ fontSize: "16px", fontWeight: 700, marginTop: "10px" }}
        >
          How it works:
        </Typography>

        <Typography sx={{ fontSize: "16px" }}>
          1. <b>Set Your Sustained Goal</b>: Indicate the number of members
          you&lsquo;re committed to supporting for sustained positive outcomes.
        </Typography>

        <Typography sx={{ fontSize: "16px" }}>
          2. <b>Monitor Continuity</b>: Keep track of the clients who
          consistently reach their goals, maintaining their conqueror status.
        </Typography>

        <Typography sx={{ fontSize: "16px" }}>
          3. <b>Earn Ongoing Rewards</b>:{" "}
          {`For each member who retains their
            conqueror status quarter after quarter, you earn a $${SUSTAINED_CONQUEROR_GOAL_COST} bonus.`}
        </Typography>

        <Typography sx={{ fontSize: "16px" }}>
          4. <b>Goal Guidelines</b>: The maximum number of members you can set
          is limited to members eligible for sustained status.
        </Typography>

        <Box sx={{ marginTop: "16px" }}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ borderRadius: "8px" }}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const CardHeader = ({
  title,
  tooltipText,
}: {
  title: string;
  tooltipText?: string;
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        fontWeight="bold"
        fontSize="12px"
        sx={{
          textAlign: "center",
          textTransform: "uppercase",
          whiteSpace: "pre-line",
        }}
      >
        {title}
      </Typography>

      {tooltipText ? (
        <Tooltip arrow title={tooltipText} placement="top">
          <InformationCircleIcon
            style={{
              color: "#0F172A59",
              height: "16px",
              width: "16px",
              flexShrink: 0,
            }}
          />
        </Tooltip>
      ) : null}
    </Stack>
  );
};

const ConquerorCardBody = ({
  currentCount,
  goalCount,
  activeMemberCount,
  dollarsPerConqueror,
  onClickSetGoal,
  hideGoals,
  canEditGoals,
}: {
  currentCount: number;
  goalCount: number;
  activeMemberCount: number;
  dollarsPerConqueror: number;
  onClickSetGoal: () => void;
  hideGoals?: boolean;
  canEditGoals?: boolean;
}) => {
  if (activeMemberCount === 0) {
    return (
      <Typography textAlign="center" color="#0F172A59">
        Check back when you have members
      </Typography>
    );
  }

  if (!goalCount || hideGoals) {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography fontWeight="bold" fontSize="18px">
          {currentCount}
        </Typography>

        {!hideGoals ? (
          <Button
            color="nocdBlue"
            variant="text"
            sx={{
              fontWeight: "normal",
              textTransform: "none",
              p: 0,
              "&:hover": {
                background: "none",
              },
            }}
            disabled={!canEditGoals}
            onClick={onClickSetGoal}
          >
            Set a bonus goal
          </Button>
        ) : null}
      </Stack>
    );
  }

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={0.5}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
          {currentCount ?? 0}
        </Typography>
        <Typography
          sx={{
            color: "#0F172A59",
            fontSize: "14px",
          }}
        >
          of
        </Typography>
        <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
          {goalCount ?? 0}
        </Typography>
        <Typography
          sx={{
            color: "#0F172A59",
            fontSize: "14px",
          }}
        >
          members
        </Typography>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={0.5}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
          ${currentCount * dollarsPerConqueror}
        </Typography>
        <Typography
          sx={{
            color: "#0F172A59",
            fontSize: "14px",
          }}
        >
          of
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
          ${goalCount * dollarsPerConqueror}
        </Typography>
        <Typography
          sx={{
            color: "#0F172A59",
            fontSize: "14px",
          }}
        >
          bonus
        </Typography>

        {!hideGoals ? (
          <IconButton
            type="button"
            onClick={onClickSetGoal}
            size="small"
            disabled={!canEditGoals}
          >
            <EditIcon
              fontSize="inherit"
              style={{
                color: "#0F172A59",
              }}
            />
          </IconButton>
        ) : null}
      </Stack>
    </Stack>
  );
};

const ConquerorCard = ({
  title,
  tooltipText,
  currentCount,
  goalCount,
  onClickSetGoal,
  hideGoals,
  canEditGoals,
  activeMemberCount,
  dollarsPerConqueror,
}: {
  title: string;
  tooltipText?: string;
  currentCount: number;
  goalCount: number;
  activeMemberCount: number;
  dollarsPerConqueror: number;
  onClickSetGoal: () => void;
  hideGoals?: boolean;
  canEditGoals?: boolean;
}) => {
  return (
    <Stack flex={1} justifyContent="center" minWidth={200}>
      <CardHeader title={title} tooltipText={tooltipText} />
      <ConquerorCardBody
        currentCount={currentCount}
        goalCount={goalCount}
        activeMemberCount={activeMemberCount}
        dollarsPerConqueror={dollarsPerConqueror}
        onClickSetGoal={onClickSetGoal}
        hideGoals={hideGoals}
        canEditGoals={canEditGoals}
      />
    </Stack>
  );
};

const TreatmentAdherenceCard = ({
  title,
  tooltipText,
  currentCount,
  goalCount,
  currentDollarAmount,
  goalDollarAmount,
}) => {
  return (
    <Stack flex={1} justifyContent="center" minWidth={200}>
      <CardHeader title={title} tooltipText={tooltipText} />
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={0.5}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            {currentCount ?? 0}
          </Typography>
          <Typography
            sx={{
              color: "#0F172A59",
              fontSize: "14px",
            }}
          >
            of
          </Typography>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            {goalCount ?? 0}
          </Typography>
          <Typography
            sx={{
              color: "#0F172A59",
              fontSize: "14px",
            }}
          >
            members
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={0.5}
        >
          <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
            ${currentDollarAmount ?? 0}
          </Typography>
          <Typography
            sx={{
              color: "#0F172A59",
              fontSize: "14px",
            }}
          >
            of
          </Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
            ${goalDollarAmount ?? 0}
          </Typography>
          <Typography
            sx={{
              color: "#0F172A59",
              fontSize: "14px",
            }}
          >
            bonus
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const ConquerorMilestoneBadge = ({ count }: { count: number }) => {
  if (!count) {
    return null;
  }

  if (count < 10) {
    return (
      <Image
        width={30}
        height={30}
        src="/images/therapist-badges/current-one.svg"
      />
    );
  }

  if (count < 50) {
    return (
      <Image
        width={30}
        height={30}
        src="/images/therapist-badges/current-ten.svg"
      />
    );
  }

  if (count < 100) {
    return (
      <Image
        width={30}
        height={30}
        src="/images/therapist-badges/current-fifty.svg"
      />
    );
  }

  if (count < 500) {
    return (
      <Image
        width={30}
        height={30}
        src="/images/therapist-badges/current-one-hundred.svg"
      />
    );
  }

  if (count < 1000) {
    return (
      <Image
        width={30}
        height={30}
        src="/images/therapist-badges/current-five-hundred.svg"
      />
    );
  }

  if (count >= 1000) {
    return (
      <Image
        width={30}
        height={30}
        src="/images/therapist-badges/current-one-thousand.svg"
      />
    );
  }

  return null;
};

const AllTimeConquerorCard = ({ count }: { count: number }) => {
  if (!count) {
    return null;
  }

  return (
    <Stack
      minWidth={200}
      flex={1}
      sx={{
        background: "linear-gradient(180deg, #F3F2F7 0%, #F1F0F7 100%)",
      }}
      borderRadius="4px"
      p={1}
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        fontWeight="bold"
        fontSize="12px"
        sx={{
          textAlign: "center",
          textTransform: "uppercase",
          whiteSpace: "pre-line",
        }}
      >
        all time conquerors
      </Typography>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <ConquerorMilestoneBadge count={count} />

        <Typography fontWeight="bold" fontSize="18px">
          {count}
        </Typography>
      </Stack>

      <Button
        target="_blank"
        component="a"
        href="/progress"
        color="nocdBlue"
        sx={{
          p: 0,
          "&:hover": {
            background: "none",
          },
          fontWeight: "normal",
          textTransform: "none",
        }}
        variant="text"
      >
        See progress
      </Button>
    </Stack>
  );
};

interface MetricsProps extends BoxProps {
  clinicianEmail?: string | null;
}

const MetricsWithTreatmentAdherence = ({
  clinicianEmail,
  ...boxProps
}: MetricsProps) => {
  const [
    isBonusEligibilityExplanationDialogOpen,
    setIsBonusEligibilityExplanationDialogOpen,
  ] = useState(false);
  const [
    isTreatmentConquerorGoalDialogOpen,
    setIsTreatmentConquerorGoalDialogOpen,
  ] = useState(false);
  const [
    isSustainedConquerorGoalDialogOpen,
    setIsSustainedConquerorGoalDialogOpen,
  ] = useState(false);

  const {
    data: progress,
    isLoading: isLoadingProgress,
    error: progressError,
  } = useProgressOverview(
    {
      staleTime: clinicianEmail ? 0 : Infinity,
    },
    clinicianEmail ?? null
  );

  const {
    data: adherence,
    isLoading: isLoadingAdherence,
    error: adherenceError,
  } = useTreatmentAdherenceBonus(clinicianEmail, {
    staleTime: Infinity,
  });

  const currentQuarter = 4;
  const treatmentConquerorHeaderCopy = `Treatment Conquerors`;
  const sustainedConquerorHeaderCopy = `Sustained Conquerors`;

  return (
    <Box
      sx={{
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        p: 3,
        minHeight: 200,
      }}
      {...boxProps}
    >
      <Stack direction="row" justifyContent="center" alignItems="center" mb={2}>
        <Typography
          sx={{
            fontSize: "14px",
            color: "#ABAEB4",
            fontWeight: "700",
          }}
        >
          Q{currentQuarter} BONUS
        </Typography>
      </Stack>

      {
        // eslint-disable-next-line no-nested-ternary
        isLoadingProgress || isLoadingAdherence ? (
          <Box
            display="flex"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={32} />
          </Box>
        ) : progressError || adherenceError ? (
          <Box
            display="flex"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography color="error">Error loading metrics</Typography>
          </Box>
        ) : (
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              flexWrap="wrap"
              gap={2}
            >
              <AllTimeConquerorCard count={progress?.total_conqueror_count} />
              <ConquerorCard
                title={treatmentConquerorHeaderCopy}
                tooltipText="Members on your caseload who achieve clinically significant outcomes for the first time are Conquerors. You are rewarded $250 for every Member you help achieve Conqueror status."
                currentCount={progress?.treatment_conquerors ?? 0}
                goalCount={progress?.treatment_conquerors_goal ?? 0}
                activeMemberCount={progress?.active_members ?? 0}
                dollarsPerConqueror={NEW_CONQUEROR_GOAL_COST}
                onClickSetGoal={() =>
                  setIsTreatmentConquerorGoalDialogOpen(true)
                }
                hideGoals={!progress?.should_show_conquerors_goals}
                canEditGoals={
                  // If clinicianEmail is not null, someone is looking at their metrics on their behalf
                  clinicianEmail == null
                }
              />

              {isTreatmentConquerorGoalDialogOpen ? (
                <SetTreatmentConquerorGoalDialog
                  isOpen={isTreatmentConquerorGoalDialogOpen}
                  onClose={() => setIsTreatmentConquerorGoalDialogOpen(false)}
                  activeMembers={progress?.active_members}
                  defaultGoal={progress?.treatment_conquerors_goal}
                />
              ) : null}

              <ConquerorCard
                title={sustainedConquerorHeaderCopy}
                tooltipText="Members on your caseload who maintain their Conqueror status are Sustained Conquerors. Members can count quarter after quarter. You are rewarded $50 for each Member you help to maintain Sustained Conqueror status."
                currentCount={progress?.sustained_conquerors ?? 0}
                goalCount={progress?.sustained_conquerors_goal ?? 0}
                activeMemberCount={progress?.active_members ?? 0}
                dollarsPerConqueror={SUSTAINED_CONQUEROR_GOAL_COST}
                onClickSetGoal={() =>
                  setIsSustainedConquerorGoalDialogOpen(true)
                }
                hideGoals={!progress?.should_show_conquerors_goals}
                canEditGoals={
                  // If clinicianEmail is not null, someone is looking at their metrics on their behalf
                  clinicianEmail == null
                }
              />

              {isSustainedConquerorGoalDialogOpen ? (
                <SetSustainedConquerorGoalDialog
                  isOpen={isSustainedConquerorGoalDialogOpen}
                  onClose={() => setIsSustainedConquerorGoalDialogOpen(false)}
                  activeMembers={progress?.active_members ?? 0}
                  defaultGoal={progress?.sustained_conquerors_goal}
                />
              ) : null}

              {adherence != null &&
              adherence.should_show_treatment_adherence ? (
                <TreatmentAdherenceCard
                  title="6 session treatment adherence"
                  tooltipText="Members that reached 6 sessions within 30 days of their first session in Q4. You are rewarded $75 per member who achieved this adherence."
                  currentCount={adherence.v6_bonus_met_count}
                  goalCount={adherence.v6_bonus_elig_count}
                  currentDollarAmount={adherence.v6_bonus_met_count * 75}
                  goalDollarAmount={adherence.v6_bonus_elig_count * 75}
                />
              ) : null}

              {adherence != null &&
              adherence.should_show_treatment_adherence ? (
                <TreatmentAdherenceCard
                  title="18 session treatment adherence"
                  tooltipText="Members that reached 18 sessions this quarter and have not become a treatment conqueror yet. You are rewarded $75 per member who achieved this adherence."
                  currentCount={adherence.v18_bonus_met_count}
                  goalCount={adherence.v18_bonus_elig_count}
                  currentDollarAmount={adherence.v18_bonus_met_count * 75}
                  goalDollarAmount={adherence.v18_bonus_elig_count * 75}
                />
              ) : null}
            </Stack>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Stack
                bgcolor="#F0FDF4"
                direction="row"
                spacing={1}
                p={0.5}
                alignItems="center"
                borderRadius="4px"
              >
                <BanknotesIcon
                  style={{
                    color: "#4ADE80",
                    height: "24px",
                    width: "24px",
                  }}
                />
                <Typography variant="body2" display="flex" alignItems="center">
                  <MuiLink
                    onClick={() =>
                      setIsBonusEligibilityExplanationDialogOpen(true)
                    }
                    component="button"
                    underline="none"
                    sx={{
                      color: "#2E8EFF",
                      mr: 0.5,
                    }}
                  >
                    Click here
                  </MuiLink>{" "}
                  to see bonus requirements
                </Typography>
              </Stack>

              <BonusEligibilityExplanationDialog
                isOpen={isBonusEligibilityExplanationDialogOpen}
                onClose={() =>
                  setIsBonusEligibilityExplanationDialogOpen(false)
                }
              />
            </Box>
          </Stack>
        )
      }
    </Box>
  );
};

export default MetricsWithTreatmentAdherence;
